.output-view {
  height: 87vh;
}

.output-view::-webkit-scrollbar {
  width: 2px !important;
  background-color: white;
}

.output-view::-webkit-scrollbar-thumb {
  background-color: grey;
}

textarea::-webkit-scrollbar {
  display: none;
}
  
.customer-suggestions {
  z-index: 5;
}

.customer-suggestions > span:hover {
  background-color: #e2e5eb;
  cursor: pointer;
}

.preview-container {
  overflow-y: scroll;
}

.preview-container::-webkit-scrollbar {
  width: 2px !important;
  background-color: white;
}

.preview-container::-webkit-scrollbar-thumb {
  background-color: #0750e2;
}

.pc-lg {
  height: 80%;
}

.pc-md {
  height: 70%;
}

.pc-sm {
  height: 60%;
}

.pt-responsive {
  padding-top: 1vh !important;
}

.size-responsive button,
.size-responsive input, 
.size-responsive textarea,
.size-responsive .label-text, 
.size-responsive #dropdown-basic,
.size-responsive .radio-text,
.size-responsive .filename-display,
.size-responsive .customer-suggestions *,
.preview-pane-item {
  font-size: 1.5vh !important ;
  margin-top: 0 !important;
}

.size-responsive .menu span {
  font-size: 1.7vh !important;
}

.size-responsive .label-text {
  padding-bottom: .8vh !important;
}

.size-responsive button,
.size-responsive .filename-display {
  padding: .8vh .8vw !important;
}

.size-responsive .customer-name {
  padding: .1vh 12px !important;
  height: 3.8vh !important;
}

.mb-responsive {
  margin-bottom: 1.5vh !important;
}

.dummy-btn { 
  transition: all 1s ease;
}