.user-icon {
    height: 50px;
    width: 50px;
    border: 4px solid var(--bg-primary);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-menu {
    display: none;
    position: absolute;
    background-color: #fff;
    width: 250px;
    z-index: 1003;
    right: 10px;
    top: 80px;
    animation: opaque .2s linear forwards;
}

@keyframes opaque {
  0% {
    opacity: 0;
  }
  25% {
    opacity: .25;
  }
  50% {
    opacity: .5;
  }
  75% {
    opacity: .75;
  }
  100% {
    opacity: 1;
  }
}

.user-menu:after{
    content: ''; 
    position: absolute; 
    top: 0;
    left: 70%;
    margin-left: 20px;
    margin-top: -19px; 
    z-index: 1;
    border-bottom: solid 20px #FFF; 
    border-left: solid 15px transparent; 
    border-right: solid 15px transparent;
}
  
.profile-container:hover .user-menu {
  display: block;
}

.size-12 {
  font-size: 12px !important;
}

.m-item {
  transform: rotateY(180deg);
}