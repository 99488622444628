.card {
  border-radius: 4px;
  border: 1px solid #e2e5eb;
  padding: 12px;
}

.card.reports {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.infoHead {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 1px solid #e2e5eb;
  padding: 5px;
}

.page-length {
  width: 60px;
  padding: 4px 8px !important;
  border: 1px solid var(--border-light) !important;
  border-radius: 8px;
  background: url(./assets/caretDown.png) no-repeat right #fff;
  background-size: 24px 24px;
  -webkit-appearance: none;
  background-position-x: 32px;
  cursor: pointer;
}

.paginate-button {
  background-color: white;
  width: 30px;
  height: 30px;
  border: 1px solid #e2e5eb;
  font-size: 14px !important;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginate-button:hover {
  color: var(--text-secondary);
  background-color: #e2e5eb;
}

.paginate-button:disabled {
  background-color: #d8d9db;
  color: #a8a8a8;
}

.page-no {
  color: var(--text-primary);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.page-no:hover {
  color: #0750e2;
  cursor: pointer;
}

.page-no.active {
  color: white;
  background-color: var(--bg-primary);
}

.action {
  list-style-type: none;
  width: 100%;
  cursor: pointer;
  padding: 4px;
}

.action:hover {
  background-color: #f1f2f4;
}

.kebab-icon:hover {
  cursor: pointer;
  padding: 5px 0;
  border-radius: 4px;
  background-color: #e2e5eb;
}

.clickable {
  cursor: pointer;
}

.sort-icon {
  float: right;
  height: 18px;
  width: 18px;
}

.url-text:hover {
  text-decoration: underline !important;
}

.tool-tip {
  transform: translateX(20%) translateY(-90%);
  position: absolute;
  padding: 2px 10px;
  border-radius: 5px;
  background-color: var(--bg-primary);
  z-index: 5 !important;
  color: white;
  font-weight: bold;
  opacity: .85;
  width: 60vw;
  max-height: 60vh;
}

.drop-down {
  border: 1px solid #e2e5eb;
  width: 150px;
  border-radius: 4px;
}

.drop-down-menu {
  border: 1px solid #e2e5eb;
  align-items: flex-start;
  width: 152px;
  position: absolute;
  transform: translateY(45px);
  z-index: 2;
  border-radius: 4px;
  background-color: white !important;
}

.drop-down-item {
  padding: 4px 8px;
  width: 100%;
}

.drop-down-item:hover {
  background-color: #e2e5eb;
}

.no-caret::after {
  display: none !important; 
}

.table-container {
  display: flex;
  flex-direction: column;
}

.tbr {
  flex-grow: 1 !important;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 600px);
  overflow-y: scroll;
}

.custom-tbr {
  height: 422px !important;
}

.no-data {
  height: 90px !important;
}

.w-35 {
  width: 35%;
}

.tbr-scroll-hidden {
  overflow: hidden;
}

.tbr thead {
  position: sticky;
  top: -1px;
  background-color: white;
  z-index: 2;
  width: 100%;
}

.tbr::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
  background-color: white;
}

.tbr::-webkit-scrollbar-thumb {
  background-color: var(--bg-primary);
}

.more-menu-container {
  display: none;
  position: absolute;
  background: transparent;
  min-width: 200px;
  z-index: 5;
  transform: translateX(-160px) translateY(-25px);
}

.more-menu {
  background-color: var(--bg-secondary);
  padding: 10px 8px;
  margin-top: 35px;
}

.more-menu:after{
  content: ''; 
  position: absolute; 
  top: 0;
  margin-left: 150px;
  margin-top: 15px;
  z-index: 1;
  border-bottom: solid 20px var(--bg-secondary); 
  border-left: solid 15px transparent; 
  border-right: solid 15px transparent;
}

.more-icon:hover .more-menu-container {
  display: block;
}

.more-menu-item:hover {
  background-color: var(--bg-grey) !important;
  color: var(--text-primary);
}

.cell-height {
  height: 500px;
}

.cell-height-sm {
  height: 70px;
}