.page-layout {
    height: calc(100vh - 82px);
    padding: 24px 32px;
}

.expected-urls-error {
    position: absolute;
    right: 0;
    transform: translateY(-20px) translateX(-20px);
}

.h-90 {
    height: 90vh;
}

.publish-options-container {
    height: calc(90vh - 48px);
}

.preview-container {
    height: calc(80vh - 48px);
}