.snackbars {
  position: fixed;
  bottom: 0;
  left: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1008;
}

.snackbar {
  min-width: 40vw;
  animation: popin .2s linear forwards;
  margin-bottom: 24px;
}

@keyframes popin {
  0% {
    margin-bottom: 0;
  }
  100% {
    margin-bottom: 24px;
  }
}

.snackbar-green {
  border-left: 4px solid #2ad130 !important;
  background-color: #edffee !important;
}

.snackbar-red {
  border-left: 4px solid #d1302a !important;
  background-color: #ffeeed !important;
}

.snackbar-blue {
  border-left: 4px solid #2a57d1 !important;
  background-color: #edf3ff !important;
}

.cross {
  font-size: 16px;
  line-height: 0.5;
}

.checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #ced4da;
  border-radius: 2px;
  margin-right: 8px;
}

.check-icon {
  position: absolute;
  transform: translateX(-1px) translateY(-1px);
  border-radius: 2px;
}

.radio-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ced4da;
  box-shadow: 3px 3px 5px #cdcdcd;
}

.radio-button.filled {
  background-color: white;
  border: 1px solid #c1bebe;
}

.radio-fill {
  background-color: var(--bg-primary);
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.helper {
  padding: 2px 3px 1px 3px;
  line-height: 0.9;
  border-radius: 50%;
  font-size: 11px !important;
  cursor: help;
}

.helper-white {
  border: 1px solid white;
  color: white;
}

.helper-gray {
  border: 1px solid #8494a4;
  color: #8494a4;
}

.helper-text {
  position: absolute;
  line-height: 1.2;
  background-color: white;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px !important;
  transform: translateY(-80%) translateX(7%);
  z-index: 2;
  min-width: 240px;
}

.drop-down {
  border: 1px solid #e2e5eb;
  width: 150px;
  border-radius: 4px;
  color: #000000cc;
}

.drop-menu {
  border: 1px solid #e2e5eb;
  width: 152px;
  position: absolute;
  z-index: 2;
  border-radius: 4px;
  background-color: white !important;
}

.drop-down-menu {
  transform: translateY(5%) !important;
}

.drop-up-menu {
  transform: translateY(calc(-100% - 40px)) !important;
}

.drop-down-item {
  width: 100%;
  cursor: pointer;
}

.drop-down-item:hover {
  background-color: #e2e5eb;
}

.ninadata-loader {
  background-color: rgba(0, 0, 0, 0.432);
  color: white;
  min-height: 100vh;
  top: 0;
  left: 0;
  z-index: 1007;
}

.loading-text {
  font-size: 55px;
}

.ellipses {
  position: absolute;
  font-size: inherit;
}

.date-selector {
  width: 130px;
}

.dropdown-items {
  color: var(--text-primary) !important;
}

.metric-title {
  padding: 14px 0;
  margin: 0;
  display: block;
}

.metric-value {
  margin-top: 2px;
  font-size: 27px;
  padding-bottom: 18px;
  margin-bottom: 30px;
}

.unit {
  font-size: 18px;
}

.menu {
  max-height: 30vh;
  overflow-y: scroll;
}

.hovered-text {
  position: absolute;
  background-color: #000000cc;
  z-index: 10;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  transform: translateY(calc(-100% - 2px)) !important
}

.filename-display {
  animation: animate .5s linear forwards;
  width: 20vw;
  overflow-x: hidden;
}

@keyframes animate {
  1% {
    width: 9.5vw;
  }
  20% {
    width: 12.5vw;
  }
  40% {
    width: 15vw;
  }
  60% {
    width: 17vw;
  }
  80% {
    width: 18.5vw;
  }
  99% {
    width: 19.5vw;
  }
}

.modal {
  position: fixed;
  inset: 0; 
  background-color: rgba(0, 0, 0, 0.357);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1006;
  padding: 0 10%;
}

.modal-content {
  animation: grow-out .3s linear forwards;
  max-height: 90vh;
}

@keyframes grow-out {
  0% {
    transform: scale(.7);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    transform: scale(1);
  }
}

.close-container {
  position: absolute;
  right: 0;
  transform: translateY(-12px) translateX(12px);
  opacity: 0.5;
}

.close-container:hover {
  opacity: 1;
}

.hint {
  position: absolute;
  background-color:  rgba(0, 0, 0, 0.808);
  right: 0;
  padding: 4px 12px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  transform: translateY(-30px) translateX(10px);
}

.input-box {
  padding: 7px 12px;
}

.mini-label {
  position: absolute;
  background-color: white;
  font-size: 11px;
  padding: 0 6px;
  color: var(--text-secondary);
  transform: translateY(-7px) translateX(7px);
  animation: fly .2s linear forwards;
}

.dropdown-mini-label {
  position: absolute;
  background-color: white;
  font-size: 11px;
  padding: 0 6px;
  color: var(--text-secondary);
  transform: translateY(-18px) translateX(-6px);
}

@keyframes fly {
  0% {
    transform: translateY(-1px) translateX(12px);
  }
  50% {
    transform: translateY(-4px) translateX(9px);
  }
  99% {
    transform: translateY(-6px) translateX(6px);
  }
}

.input-error {
  position: absolute;
  background-color: white;
  right: 4px;
  padding: 0 6px;
  transform: translateY(-10px);
  animation: fly .1s linear forwards;
}

.rounded-widget {
  border-radius: 10px;
}

.rounded-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.rounded-body {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.readOnly-gradient {
  background-image: linear-gradient(to bottom right, white, #efefef6c, #efefef9c, #efefef6c, white);
}

.menu-option {
  color: var(--text-secondary);
}

.menu-option:hover {
  color: var(--text-primary);
  background-color: var(--bg-grey);
  border-radius: 4px;
}

.fade-out {
  animation: fade-out 2s linear forwards;
}

@keyframes fade-out {
  20% {
    opacity: .8;
  }
  40% {
    opacity: .6;
  }
  60% {
    opacity: .4;
  }
  80% {
    opacity: .2;
  }
  100% {
    opacity: 0;
  }
}

.cw-0 {
  width: 6em !important;
}

.cw-1 {
  width: 115px !important;
}

.cw-2 {
  width: 140px !important;
}

.cw-3 {
  width: 185px !important;
}