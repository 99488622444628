.custom-checkbox {
    border: 2px solid #c1bebe;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    background-color: white;
    margin: 4px 20px;
    box-shadow: 3px 3px 5px #cdcdcd;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-checkbox-fill {
    background-color: var(--bg-primary);
    z-index: 3;
    height: 10px;
    width: 10px;
    border-radius: 2px;
}

.domains-container-sm {
    height: 200px;
}

.domains-container-md {
    height: 385px;
}

.file-container {
    border: 3px dashed var(--bg-primary);
    height: 250px !important;
    font-size: 30px;
    margin: 12px;
}

.file-container:hover {
    box-shadow: 0px 8px 10px #c1bebe;
}
