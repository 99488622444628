.App {
  height: 100vh !important;
}

.page-container {
  height: calc(100% - 82px);
}

.text-selected {
  color: #0750e2;
}

.tab-section-border {
  border-bottom: 1px solid #0750e2;
}

.tabs {
  max-width: 25%;
}

.tab-false:hover {
  color: #0750e2;
}

.tab-active {
  color: #0750e2;
}

.outline-active {
  border: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top: 3px solid #0750e2;
}

.text-danger {
  font-size: 12px;
}

.keywords-loading {
  position: absolute;
  transform: translateY(-165px);
}

.back-button {
  position: absolute;
  bottom: 24px;
  right: 24px;
}

.circle {
  border-radius: 50%;
  border: 4px solid #efefef;
  padding: 8px;
  background-color: white !important;
  z-index: 1;
}

.circle.active {
  background-color: #0750e2 !important;
}

.stepper {
  width: 800px;
}

.stepper-line {
  height: 4px;
  width: 620px;
  background-color: #efefef;
  position: absolute;
  top: 145px;
  transform: translateX(15px);
}

.welcome {
  height: 30%;
  width: 30%;
  margin-top: 20vh;
}

.options-filter {
  margin-right: 12px;
  margin-bottom: 5px;
}

.error-text {
  position: absolute;
  transform: translateY(20%);
}

.network-failure {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 1004;
  background-color: #0000009c;
  color: #ece1e1;
}

.failure {
  position: absolute;
  left: 0;
  color: #a8a8a8;
}

.dropdown-selector { 
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.geolocations {
  width: 400px;
}

.dropdown-item:hover {
  color: var(--text-primary) !important;
}

.taxonomies-container {
  height: 260px;
}

.checkbox-container {
  width: 1.3em;
  height: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #dee2e6;
  border-radius: 3px !important;
}

.chevron-container {
  width: 14px;
}

.overview-container {
  height: 100vh !important;
  display: flex;
  flex-direction: column;
}

.cm-1 {
  margin: 24px;
}

.cm-2 {
  margin: 32px;
}

.w-49 {
  width: 49% !important;
}