.label-text {
  color: var(--text-secondary);
  font-size: 16px !important;
  font-family: "Poppins";
  margin-right: 2px !important;
}

.widgets {
  border: 1px solid #e2e5eb;
  border-radius: 4px;
  padding: 12px;
}

.text-active {
  font-family: "Poppins-bold";
  color: #0750e2;
}

.scrollable {
  overflow-y: scroll;
}

.scrollable::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  background-color: white;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: var(--bg-primary);
  border-radius: 50px;
}
