a {
  text-decoration: none !important;
  color: var(--text) !important;
}

textarea {
  resize: none !important;
}

span {
  font-size: 14px;
}

p {
  font-size: 16px !important;
}

button {
  font-size: 14px !important;
}

button:disabled {
  background-color: #d8d9db !important;
  color: #a8a8a8 !important;
}

input {
  font-size: 14px !important;
}

strong {
  line-height: 1px;
}

label {
  margin: 0 !important;
  padding-bottom: 14px !important;
  font-family: "Poppins-bold";
  line-height: 1;
}

.text--body {
  color: var(--text-body) !important;
}

.text--primary {
  color: var(--text-primary) !important;
}

.text--secondary {
  color: var(--text-secondary) !important;
}

.text--tertiary {
  color: var(--text-tertiary) !important;
}

.bg--body {
  background-color: var(--bg-body) !important;
}

.bg--primary {
  background-color: var(--bg-primary) !important;
}

.bg--secondary {
  background-color: var(--bg-secondary) !important;
}

.bg--tertiary {
  background-color: var(--bg-tertiary) !important;
}

.text--grey {
  color: var(--text-grey) !important;
}

.bg--grey {
  background-color: var(--bg-grey) !important;
}

.bg--gray {
  background-color: #F5F5F5 !important;
}

.bg--light {
  background-color: #EFF2F7 !important;
}

.border--light {
  border: 1px solid var(--border-light) !important;
}

.text--white {
  color: white !important;
}

.text--red {
  color: #e52323 !important;
}

.text--green {
  color: #01a214 !important;
}

.text--blue {
  color: var(--text-primary) !important;
}


select {
  width: 160px;
  padding: 8px 10px !important;
  font-size: 14px !important;
  border: 1px solid #e2e5eb !important;
  border-radius: 4px;
  background: url(./assets/icons/misc/caretDown.png) no-repeat right #fff;
  background-size: 20px 20px;
  -webkit-appearance: none;
  background-position-x: 130px;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
