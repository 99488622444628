.sidebar {
  padding: 0;
  max-height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 275px;
  z-index: 1003 !important;
  position: sticky;
  top: 0;
  left: 0;
  transition: 0.1s ease-in;
}

.collapsed {
  width: 70px;
  transition: 0.1s ease-in;
}

.logo-zone {
  height: 60px;
}

.sidebar-menu-item {
  text-decoration: none;
  display: block;
  font-size: 14px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.hovered-item {
  color: var(--text-primary) !important;
  background-color: var(--bg-grey);
  animation: animate .3s linear forwards;
  border-right: 2.5px solid var(--text-secondary);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

@keyframes animate {
  0% {
    width: 80%;
  }
  25% {
    width: 85%;
  }
  50% {
    width: 90%;
  }
  75% {
    width: 95%;
  }
  100% {
    width: 100%;
  }
}

.logout-area {
  margin: 0 12px;
}

.collapse-button {
  position: absolute;
  background: white;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid #273b4e1a;
  left: 100%;
  top: 45px;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 2;
  transition: 0.1s ease-in-out;
}

.rotate-button {
  transform: rotate(180deg) translateX(10px);
}

.text-selected {
  background-color: var(--bg-tertiary) !important;
  border-right: 2.5px solid var(--text-primary);
}

.sidebar-sub-menu {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 250px;
  right: -262px;
  top: -30px;
}

.sidebar-sub-menu:before{
  content: ''; 
  position: absolute; 
  top: 35px;
  left: -20px;
  border-right: solid 20px white; 
  border-top: solid 15px transparent; 
  border-bottom: solid 15px transparent;
}

.menu-display {
  display: block;
  animation: opaque .2s linear forwards;
}

@keyframes opaque {
  0% {
    opacity: 0;
  }
  25% {
    opacity: .25;
  }
  50% {
    opacity: .5;
  }
  75% {
    opacity: .75;
  }
  100% {
    opacity: 1;
  }
}

.caret-right {
  position: absolute;
  right: 8px;
  top: 14px;
}